import 'jquery.appear/jquery.appear'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap-datepicker'
import 'jquery-form-validator/form-validator/jquery.form-validator'
import 'jquery-form-validator/form-validator/security'
import 'jquery-form-validator/form-validator/file'
import 'chart.js/dist/Chart'
import 'cocoon-js'
import 'blueimp-file-upload/js/jquery.fileupload'
import 'select2/dist/js/select2.min'
import 'daterangepicker/daterangepicker'
import 'fullcalendar/dist/fullcalendar'
import 'mark.js/dist/jquery.mark.min'
import 'jquery-mask-plugin/src/jquery.mask'
import './polyfill'
import 'bootstrap-multiselect'
import 'datatables.net-bs4/js/dataTables.bootstrap4.min'
import 'datatables.net-scroller-bs4/js/scroller.bootstrap4.min'
import 'datatables.net-select-bs4/js/select.bootstrap4.min'
import 'datatables.net-fixedcolumns-bs4'

var App = App || {};

import Bootbox            from 'bootbox/bootbox'
import Rails              from '@rails/ujs'
import Turbolinks         from 'turbolinks'
import { createConsumer } from "@rails/actioncable"
import Flash              from './flash'
import I18n               from 'i18n-js'
import Moment             from 'moment/moment'

import { parsePhoneNumber } from 'libphonenumber-js'

App.bootbox          = Bootbox;
App.cable            = createConsumer();
App.flash            = Flash;
App.i18n             = I18n;
App.moment           = Moment;
App.parsePhoneNumber = parsePhoneNumber;

window.App  = App;

Rails.start();
Turbolinks.start();
Flash.start();

I18n.currentLocale();

$.fn.select2.defaults.set('theme', 'bootstrap4');
